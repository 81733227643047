import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import { readingTime as readingTimeHelper } from "@tryghost/helpers"
import { Layout } from "../components/common"
import { MetaData } from "../components/common/meta"
import AuthorAvatar from '../components/common/AuthorAvatar'
import ShareButtons from '../components/common/ShareButtons'

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */

const Post = ({ data, location, pageContext }) => {
    const post = data.ghostPost

    const readingTime = readingTimeHelper(post)
    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout pageTitle={post.title} nextPost={data.next} previousPost={data.previous}>
                <div className="container">
                    <article className="content">
                        {/*post.feature_image ?
                            <figure className="post-feature-image">
                                <img src={ post.feature_image } alt={ post.title } />
                            </figure> : null */}
                        <section className="post-full-content">
                            <header>
                                <h1 className="content-title">{post.title}</h1>
                            </header>
                            <section css={{ marginBottom: 20 }}>
                                <div css={{ display: `flex`, padding: 10, borderTop: `1px solid #e2e2e2`, alignItems: `center` }}>
                                    <AuthorAvatar profileImage={post.primary_author.profile_image} name={post.primary_author.name}/>
                                    <section css={{ lineHeight: `2.2rem` }}>
                                        <h4
                                            css={{
                                                margin: `0 0 3px`,
                                                fontSize: `1.3rem`,
                                            }}
                                        >
                                            {post.primary_author.name}
                                        </h4>
                                        <div
                                            css={{
                                                display: `flex`,
                                                fontSize: `1.2rem`,
                                                textTransform: `uppercase`,
                                            }}
                                        >
                                            <time dateTime={post.published_at}>
                                                {post.published_at_pretty}
                                            </time>
                                            <span
                                                css={{ margin: `0 5px` }}
                                            >{` `}</span>
                                            <span>{readingTime}</span>
                                        </div>
                                    </section>
                                </div>
                            </section>
                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                            <ShareButtons url={location.href}/>
                        </section>
           
                    </article>
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!, $previous: String, $next: String) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        previous: ghostPost(slug: { eq: $previous}) {
            ...GhostPostFields
        }
        next: ghostPost(slug: { eq: $next}) {
            ...GhostPostFields
        }
    },
`
